<template>
    <b-modal id="reset-password-modal" centered header-bg-variant="primary" @ok="resetPassword">
        <div slot="modal-header" class="w-100">
            <h2 class="text-center">{{ $t('login.resetPasswordTitle') }}</h2>
        </div>

        <p>{{ $t('login.resetPasswordDesc') }}</p>

        <b-form-group id="fieldset1" label-for="reset-password-input" :invalid-feedback="invalidEmailFeedback" :valid-feedback="validEmailFeedback" f
                      :state="emailState">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text><i class="icon-user"/></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input id="reset-password-input" :placeholder="$t('login.enterEmail')" :state="emailState" v-model.trim="email"/>
            </b-input-group>
        </b-form-group>

        <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "Modal",

        data() {
            return {
                email: "",
            }
        },

        computed: {
            emailState() {
                let re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
                return re.test(this.email);
            },
            invalidEmailFeedback() {
                if (this.email.length > 0) {
                    return this.$t('login.emailNotFormattedProperly');
                } else {
                    return this.$t('login.emailEmpty');
                }
            },
            validEmailFeedback() {
                return this.emailState === true ? this.$t('login.emailFormattedProperly') : '';
            },
        },

        methods: {
            resetPassword(bvEvt) {
                this.$axios({
                    method: 'get',
                    url: '/reset-password',
                    params: {
                        'email': this.email,
                    },
                    timeout: 10000
                }).then(response => {
                    this.$root.$emit('showInfo', response.data);
                    this.$root.$emit('bv::show::modal', 'info-modal');
                }).catch((err) => {
                    this.$root.$emit('showError', this.$t('login.emailCouldNotBeSent'));
                    this.$root.$emit('bv::show::modal', 'error-modal');
                });
            },
        }

    }
</script>

<template>
    <b-modal id="register-user-modal" centered header-bg-variant="primary" @ok="registerUser">
        <div slot="modal-header" class="w-100">
            <h2 class="text-center">{{ $t('login.register') }}</h2>
        </div>

        <p>{{ $t('login.registerModalDesc') }}</p>

        <b-form-group id="fieldset1" label-for="email-input" :invalid-feedback="invalidEmailFeedback" :valid-feedback="validEmailFeedback" f
                      :state="emailState">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text>
                        <i class="icon-user"/>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input id="email-input" :placeholder="$t('login.enterEmail')" :state="emailState" v-model.trim="email"/>
            </b-input-group>
        </b-form-group>

        <b-form-group id="fieldset2" label-for="password-input" :invalid-feedback="invalidPasswordFeedback" :valid-feedback="validPasswordFeedback" f
                      :state="passwordState">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text>
                        <i class="icon-lock"/>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input id="password-input" type="password" :placeholder="$t('login.enterPassword')" :state="passwordState" v-model.trim="password"/>
            </b-input-group>
        </b-form-group>

        <b-form-group id="fieldset3" label-for="password-confirmation-input" :invalid-feedback="invalidPasswordConfirmationFeedback"
                      :valid-feedback="validPasswordConfirmationFeedback" f :state="passwordConfirmationState">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text>
                        <i class="icon-lock"/>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input id="password-confirmation-input" type="password" :placeholder="$t('login.confirmPassword')" :state="passwordConfirmationState"
                              v-model.trim="passwordConfirmation"/>
            </b-input-group>
        </b-form-group>

        <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        <div slot="modal-cancel">{{ $t('general.cancel') }}</div>
    </b-modal>
</template>

<script>
    export default {
        name: "Modal",

        data() {
            return {
                email: "",
                password: "",
                passwordConfirmation: "",
            }
        },

        computed: {
            emailState() {
                let re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
                return re.test(this.email);
            },
            invalidEmailFeedback() {
                if (this.email.length > 0) {
                    return this.$t('login.emailNotFormattedProperly');
                } else {
                    return this.$t('login.emailEmpty');
                }
            },
            validEmailFeedback() {
                return this.emailState === true ? this.$t('login.emailFormattedProperly') : ''
            },

            passwordState() {
                return this.password.length >= 6 ? true : false
            },
            invalidPasswordFeedback() {
                if (this.password.length > 6) {
                    return '';
                } else if (this.password.length > 0) {
                    return this.$t('login.atLeast6Chars');
                } else {
                    return this.$t('login.passwordMustNotBeEmpty');
                }
            },
            validPasswordFeedback() {
                return this.passwordState === true ? this.$t('login.correct') : ''
            },

            passwordConfirmationState() {
                return this.passwordConfirmation.length >= 6 ? true : false;
            },
            invalidPasswordConfirmationFeedback() {
                if (this.passwordConfirmation.length > 6) {
                    return ''
                } else if (this.passwordConfirmation.length > 0) {
                    return this.$t('login.atLeast6Chars');
                } else {
                    return this.$t('login.passwordMustNotBeEmpty');
                }
            },
            validPasswordConfirmationFeedback() {
                return this.passwordConfirmationState === true ? this.$t('login.correct') : '';
            }
        },

        methods: {
            registerUser(bvEvt) {
                this.$axios({
                    method: 'post',
                    url: '/register',
                    data: {
                        'email': this.email,
                        'password': this.password,
                        'passwordConfirmation': this.passwordConfirmation

                    },
                    timeout: 10000
                }).then(response => {
                    this.$root.$emit('showInfo', response.data);
                    this.$root.$emit('bv::show::modal', 'info-modal');
                }).catch((err) => {
                    let error = err.response.data;
                    if (error.type === "ValidationError") {
                        this.$root.$emit('showError', error.body);
                    } else {
                        this.$root.$emit('showError', error);
                    }
                    this.$root.$emit('bv::show::modal', 'error-modal');
                }).finally(() => {
                });
            },
        }
    }
</script>

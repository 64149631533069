<template>
    <b-modal id="error-modal" centered ok-only header-bg-variant="danger" title="Error">
        <p class="my-4"><span style="word-wrap:break-word;">{{ content }}</span></p>
    </b-modal>
</template>

<script>
    export default {
        name: "ErrorModal",

        data() {
            return {
                content: "There was an error.."
            }
        },

        mounted() {
            this.$root.$on('showError', this.showError);
        },

        destroyed() {
            this.$root.$off('showError');
        },

        methods: {
            showError(content) {
                this.content = content;
            }
        }
    }
</script>

<style scoped>

</style>

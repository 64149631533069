<!--suppress ALL -->
<template>
    <div class="app flex-row align-items-center animated fadeIn" style="background: #f8f9fa;">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="8">
                    <b-card-group>
                        <b-card no-body class="p-4 modules-container">
                            <b-card-body>
                                <b-form @submit.prevent="handleLogin" novalidate>
                                    <h1>Login</h1>
                                    <p class="text-muted">{{ $t('login.signInDesc') }}</p>
                                    <b-form-group>
                                        <b-input-group class="mb-3">
                                            <b-input-group-prepend>
                                                <b-input-group-text><i class="icon-user"/></b-input-group-text>
                                            </b-input-group-prepend>
                                            <b-form-input name="email" type="text" class="form-control" placeholder="Email" autocomplete="email"
                                                          v-model="email"/>
                                        </b-input-group>

                                        <b-input-group class="mb-4">
                                            <b-input-group-prepend>
                                                <b-input-group-text><i class="icon-lock"/></b-input-group-text>
                                            </b-input-group-prepend>
                                            <b-form-input name="password" type="password" class="form-control" placeholder="Password"
                                                          autocomplete="current-password" v-model="password"/>
                                        </b-input-group>

                                        <p style="color: #f86c6b" v-if="this.hasError && !this.resend">
                                            {{ errorMessage }}
                                        </p>
                                        <p style="color: #f86c6b" v-else-if="this.hasError && this.resend">
                                            {{ errorMessage }}
                                            <b-button class="p-0 m-0" type="button" v-if="this.hasError" variant="link"
                                                      @click="resendVerificationEmail()">
                                                (Resend)
                                            </b-button>
                                        </p>
                                        <p style="color: #4dbd74" v-else-if="this.showInfo">
                                            {{ infoMessage }}
                                        </p>

                                    </b-form-group>
                                    <b-row>
                                        <b-col cols="6">
                                            <b-button type="submit" variant="primary" class="px-4" style="width: 100px;">
                                                Login
                                            </b-button>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-button cols="6" type="button" variant="primary"
                                                      class="float-right d-none d-block d-sm-block d-md-block d-lg-none"
                                                      style="width: 100px;" @click="showRegisterModal()">
                                                {{ $t('login.register') }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" class="text-left">
                                            <b-button variant="link" class="px-0" @click="showResetPasswordModal()">
                                                {{ $t('login.forgotPassword') }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>
                        <b-card no-body class="text-white bg-primary py-5 p-4 d-md-down-none modules-container">
                            <b-card-body class="text-center">
                                <div>
                                    <h2>{{ $t('login.register') }}</h2>
                                    <p>{{ $t('login.registerDesc') }}</p>
                                    <b-button variant="primary" class="active mt-3" @click="showRegisterModal()">
                                        {{ $t('login.registerNow') }}
                                    </b-button>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>

            <RegisterUserModal/>

            <ResetPasswordModal/>

            <InfoModal/>

            <ErrorModal/>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RegisterUserModal from "@/components/account/RegisterUserModal";
    import ResetPasswordModal from "@/components/account/ResetPasswordModal";
    import ErrorModal from "@/components/general-purpose-modals/ErrorModal";
    import InfoModal from "@/components/general-purpose-modals/InfoModal";

    export default {
        name: 'Login',

        components: {InfoModal, ErrorModal, RegisterUserModal, ResetPasswordModal},

        data() {
            return {
                email: "",
                password: "",
                hasError: false,
                errorMessage: "",
                resend: false,
                userId: Number,
                showInfo: false,
                infoMessage: ""
            }
        },

        computed: {
            ...mapGetters({
                authError: 'auth/authenticationError',
                authErrorCode: 'auth/authenticationErrorCode',
            })
        },

        created() {
            if (this.$route.query.as === 'demo') {
                this.email = 'demo@things.al';
                this.password = 'demo@things';
                this.handleLogin();
            }
        },

        methods: {
            ...mapActions('auth', [
                'login'
            ]),

            handleLogin() {
                this.login({email: this.email, password: this.password}).then(() => {
                    if (this.authError.type) {
                        this.resend = false;
                        this.hasError = true;
                        if (this.authError.type === "InvalidCredentials") {
                            this.errorMessage = this.$t('login.invalidCredentials');
                        } else if (this.authError.type === "NotVerified") {
                            this.resend = true;
                            this.errorMessage = this.$t('login.notVerified');
                            this.userId = this.authError.body;
                        } else if (this.authError.type === "NetworkError") {
                            this.errorMessage = this.$t('login.errorOccurred');
                        }
                    } else if (this.authErrorCode && this.authErrorCode === 503) {
                        this.hasError = true;
                        this.errorMessage = this.$t('login.errorOccurred');
                    } else {
                        if (this.$route.query.as === 'demo') {
                            localStorage.setItem('is_demo', "true");
                        } else {
                            localStorage.setItem('is_demo', "false");
                        }
                    }

                });
            },

            showRegisterModal() {
                this.$root.$emit('bv::show::modal', 'register-user-modal');
            },

            showResetPasswordModal() {
                this.$root.$emit('bv::show::modal', 'reset-password-modal');
            },

            resendVerificationEmail() {
                this.hasError = false;
                this.resend = false;
                this.showInfo = true;
                this.infoMessage = this.$t('login.verificationEmailSent');

                this.$axios.get('/resend-verification-email', {
                    params: {
                        userId: this.userId
                    }
                }).finally(this.userId = null);
            }
        }
    }
</script>




























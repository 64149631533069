<template>
    <b-modal id="info-modal" centered ok-only header-bg-variant="info" title="Info">
        <p class="my-4"><span style="white-space: pre;">{{ content }}</span></p>
    </b-modal>
</template>

<script>
    export default {
        name: "InfoModal",

        data() {
            return {
                content: "..."
            }
        },

        mounted() {
            this.$root.$on('showInfo', this.showInfo);
        },

        destroyed() {
            this.$root.$off('showInfo');
        },

        methods: {
            showInfo(content) {
                this.content = content;
            }
        }
    }
</script>

<style scoped>

</style>
